/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { appId, apiUrl } from "../Helpers/Config";
import { getReferenceID, removePromoCkValue, showAlert, showLoader } from "../Helpers/SettingHelper";
import cookie from "react-cookies";
import tickImage from "../../common/images/tick_popup.png";
import loadingImage from "../../common/images/loading_popup.gif";

var qs = require("qs");
class Fomoplaceorder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      payment_attempt: 0,
      validateimage: loadingImage,
      processingText: 'We are processing your order',
      globalSettings: [],
    };

    setTimeout(function(){
    $.magnificPopup.open({
      items: {
        src: ".processing",
      },
      type: "inline",
      showCloseBtn: false,
      midClick: false,
      closeOnBgClick: false,
    });
    }.bind(this),300);

  }

  componentWillReceiveProps(nextProps) {

    if (this.state.globalSettings !== nextProps.settingsArr) {
      if (Object.keys(nextProps.settingsArr).length > 0 && nextProps.settingsArr !== '') {
      this.setState({ globalSettings: nextProps.settingsArr},function(){
        this.orderPlaceorder()
      });
      }
    }
  }

  orderPlaceorder(){

    var check_order_id = localStorage.getItem('fomopay_order_id');
    var userAccountType =
      typeof cookie.load("userAccountType") === "undefined"
        ? ""
        : cookie.load("userAccountType");
    var referenceNewId = getReferenceID();	

    if(check_order_id !== "" && check_order_id !== null && check_order_id !== 'undefined'){

      var checkOrderpostObject = {
      app_id: appId,
      order_id: check_order_id,
      sub_mid: this.state.globalSettings.client_fomopay_mid_key,
      psk_key: this.state.globalSettings.client_fomopay_psk_key
      }

      var urlShringTxt = apiUrl + "fomopay/fomo_pay_order_query?app_id=" + appId + "&order_id=" + check_order_id + "&sub_mid=" +
        this.state.globalSettings.client_fomopay_mid_key+ "&psk_key=" + this.state.globalSettings.client_fomopay_psk_key;
        
        axios.get(urlShringTxt).then((res) => {
        console.log(res.data.order_placed, "res");

        if(res.data.order_placed == '1'){

          var postObject = localStorage.getItem('postObject')+
            "&login_type=" +
            userAccountType +
            "&reference_new_id=" +
            referenceNewId;

             axios.post(apiUrl + "ordersv1/submit_order", postObject).then((resorder) => {

              if (resorder.data.status === "ok") {

                localStorage.removeItem('postObject');
                localStorage.removeItem('fomopay_order_id');

                var localOrderNo = resorder.data.common.local_order_no;
                var orderID = resorder.data.common.order_id;

                    var postOrderStatus = {
                    "app_id": appId,     
                    "order_id": orderID,
                    "fomo_pay_order_id": check_order_id,
                    }

                    axios.post(apiUrl+'fomopay/order_status_update', qs.stringify(postOrderStatus)).then((captureRes) => {
                    if (captureRes.data.status === "ok") {
                       this.setState({validateimage: tickImage});
                       this.showSuccessPage(localOrderNo);
                      }
                    });

              }else{
                    this.deleteOrderCookie();
                    this.setState({processingText: 'Please check in myorders, to get order details.'})
                    setTimeout(function(){
                    //window.location.href = "/myorders";
                    },5000);
              }

            }).catch(function (error) { 
              console.log(error, "erros");
              this.deleteOrderCookie();
              this.setState({processingText: 'Please check in myorders, to get order details.'})
              setTimeout(function(){
             // window.location.href = "/myorders";
              },5000);
            }.bind(this));
                    
        }else{
            showAlert("Error", res.data.order_placed);
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });

        }
        

      });

    }else{
        setTimeout(function(){
        this.deleteOrderCookie();
        this.setState({ processingText: 'Please check in myorders, to get order details.'});
        }.bind(this),300)
        setTimeout(function(){
       // window.location.href = "/myorders";
        },5000);
    }

  }


  /* sucess page */
  showSuccessPage(localOrderNo) {
    this.deleteOrderCookie();
    cookie.save("ChkOrderid", localOrderNo, { path: "/" });
    $.magnificPopup.close();
    window.location.href = "/thankyou/" + localOrderNo;
  }

  /* this function used to  delete all cookie values */
  deleteOrderCookie() {
    removePromoCkValue();

    cookie.remove("orderPaymentMode", { path: "/" });
    cookie.remove("orderTableNo", { path: "/" });
    cookie.remove("product_remarks", { path: "/" });
    cookie.remove("orderOutletName", { path: "/" });
    //cookie.remove('orderOutletId');
    cookie.remove("carttotalitems", { path: "/" });
    cookie.remove("cartsubtotal", { path: "/" });
    cookie.remove("cartid", { path: "/" });
    cookie.remove("ocbcreferenceID", { path: "/" });
    cookie.remove("ocbcCaptureId", { path: "/" });
    cookie.remove("payment_intent", { path: "/" });

    /* Delivery avilablity */
    cookie.remove("deliveryDate", { path: "/" });
    cookie.remove("deliveryTime", { path: "/" });
    cookie.remove("unitNoOne", { path: "/" });
    cookie.remove("unitNoTwo", { path: "/" });
    cookie.remove("customer_tower_number", { path: "/" });

    cookie.remove("firstNavigation", { path: "/" });

    cookie.remove("promotion_id", { path: "/" });
    cookie.remove("promotion_applied", { path: "/" });
    cookie.remove("promotion_code", { path: "/" });
    cookie.remove("promotion_delivery_charge_applied", { path: "/" });
    cookie.remove("promotion_amount", { path: "/" });
    cookie.remove("promotion_category", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });

    /*Remove voucher*/
    cookie.remove("voucher_applied", { path: "/" });
    cookie.remove("voucher_code", { path: "/" });
    cookie.remove("voucher_amount", { path: "/" });

    cookie.remove("points_redeemed", { path: "/" });
    cookie.remove("points_used", { path: "/" });
    cookie.remove("points_amount", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });
  }

  sateValChange = (field, value) => {};

  render() {
    return (
      <div className="placeorder-main-div checkout-main-div">
        {/* Header start */}
        <Header sateValChange={this.sateValChange} />
        {/* Header End */}

        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <p>Order</p>
          </div>
        </div>

        <div className="container">
          <div className="common-inner-blckdiv">
            <div className="common-inner-banner">
              <p>Placing Order</p>
            </div>
          </div>
        </div>

        <div
          id="processing-popup"
          className="white-popup mfp-hide popup_sec processing"
        >
          <div className="pouup_in">
            <h3 className="title1 text-center">{this.state.processingText}</h3>
            <div className="process_inner">
              <div className="process_col">
                <div className="process_left">
                  <img src={this.state.validateimage} />
                </div>
                <div className="process_right">
                  <h5>Waiting for Payment Confirmation</h5>
                </div>
              </div>
            </div>
          </div>
        </div>


        {/* Footer section */}
        <Footer />
        {/* Donate popup - end */}
      </div>
    );
  }
}

const mapStateTopProps = (state) => {

  var globalSettings = Array();
  if (Object.keys(state.settings).length > 0) {
    if (state.settings[0].status === "ok") {
      globalSettings = state.settings[0].result_set;
    }
  }


  return {
    settingsArr: globalSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateTopProps, mapDispatchToProps)(Fomoplaceorder);
