/* eslint-disable */
import React, { Component } from "react";
import cookie from "react-cookies";
import axios from "axios";
import { connect } from "react-redux";
import update from "immutability-helper";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import HomeBanner from "./HomeSubCompo/HomeBanner";
import PantryProducts from "./HomeSubCompo/PantryProducts";
import PromoProducts from "./HomeSubCompo/PromoProducts";
import Blog from "./HomeSubCompo/Blog";
import Testimonial from "./HomeSubCompo/Testimonial";
import InstaFeed from "./HomeSubCompo/InstaFeed";
import NewsLetter from "./HomeSubCompo/NewsLetter";

import {
  appId,
  apiUrl,
  deliveryId,
  pickupId,
  dineinId,
  cateringId,
  reservationId,
  companyname,
} from "../Helpers/Config";
import {
  GET_GLOBAL_SETTINGS,
  GET_STATIC_BLOCK,
  GET_NORMAL_POPUP,
  GET_CATEGORY_LIST,
  GET_MENU_NAVIGATION,
} from "../../actions";
import {
  showLoader,
  hideLoader,
  validateEmailFun,
  showCustomAlert,
  stripslashes,
} from "../Helpers/SettingHelper";

import newletterImg from "../../common/images/newletter-bg.jpg";
import grambig from "../../common/images/gram-big.jpg";
import gram1 from "../../common/images/gram1.jpg";
import gram2 from "../../common/images/gram2.jpg";
import gram3 from "../../common/images/gram3.jpg";
import gram4 from "../../common/images/gram4.jpg";

import db from "../../common/images/delivery-bike.svg";
import dbwhite from "../../common/images/delivery-bike-white.svg";
import ta from "../../common/images/takeaway.svg";
import tawhite from "../../common/images/takeaway-white.svg";
import din from "../../common/images/dinein.svg";
import dinwhite from "../../common/images/dinein-white.svg";
import res from "../../common/images/reservation.svg";
import reswhite from "../../common/images/reservation-white.svg";
import cater from "../../common/images/catering.svg";
import caterwhite from "../../common/images/catering-white.svg";
import locpin from "../../common/images/location-pin.svg";
import locpinwhite from "../../common/images/location-pin-white.svg";
import homefeed from "../../common/images/insta-lhs.jpg";
import homefeed5 from "../../common/images/insta-rhs.jpg";
import homefeed1 from "../../common/images/insta-top1.jpg";
import homefeed2 from "../../common/images/insta-top2.jpg";
import homefeed3 from "../../common/images/instabtm1.jpg";
import homefeed4 from "../../common/images/insta-btm2.jpg";
import maintanceImg from "../../common/images/maintance.png";

import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";
var Parser = require("html-react-parser");
const settingsJourney = {
  items: 1,
  autoplay: false,
  loop: true,
  nav: false,
};
const settingsGallery = {
  items: 3,
  autoplay: true,
  loop: false,
  nav: false,
};
class Home extends Component {
  constructor(props) {
    super(props);

    var normalpopupFlg = "initial";
    if (
      (cookie.load("triggerAvlPop") != "" &&
        cookie.load("triggerAvlPop") != undefined) ||
      cookie.load("orderPopuptrigger") === "Yes" ||
      cookie.load("loginpopupTrigger") === "Yes" ||
      cookie.load("promoPopupTrigger") === "Yes"
    ) {
      normalpopupFlg = "trigger";
    }

    this.state = {
      users: [],
      nextavail: "",
      cartTriggerFlg: "No",
      staticblacks: [],
      homeAboutBlk: "",
      homePantryBlk: "",
      homepromoBlk: "",
      homeBlogBlk: "",
      homejournyBlk: "",
      homejournyimgBlk: [],
      homejournyimgpath: "",
      homegrammedBlk: "",
      homeLightContent: "",
      homeDarkContent: "",
      homecafe: "",
      homesubscriptionblk: "",
      homeblksorting: "",
      viewProductFlg: "no",
      viewProductSlug: "",
      normalpopuplist: [],
      normalpopupdata: [],
      globalsettings: [],
      normalpopup_status: "no",
      normalpopup_flg: normalpopupFlg,
      normalpopupstatus: "initial",
      promo_mail_id: "",
      displayType: "all",
      newsletterfields: {
        email: "",
        firstname: "",
        lastname: "",
        terms: "",
      },
      promomail_error: "",
      homeGallery: [],
      client_password_maintain: "",
      maintanace_mode: "",
      client_password_maintain_error: "",
      maintanace_popup_hide: false,
    };
    if (this.props.match.path === "/chopchoptracking/token/:tokenID") {
      if (
        this.props.match.params.tokenID !== "" &&
        typeof this.props.match.params.tokenID !== !undefined &&
        typeof this.props.match.params.tokenID !== "undefined"
      ) {
        cookie.save("chopchoptoken", "Yes", {
          path: "/",
        });
        this.props.history.push("/");
      }
    }
    this.props.getSettings();
    this.props.getCategoryList();
    this.props.getMenuNavigation();
    this.props.getNormalPopup();
    this.props.getStaticBlock();
  }

  componentWillReceiveProps(PropsData) {
    if (PropsData.staticblack !== this.state.staticblacks) {
      var homeAboutBlk = "",
        homePantryBlk = "",
        homepromoBlk = "",
        homeBlogBlk = "",
        homejournyBlk = "",
        homejournyimgpath = "",
        homegrammedBlk = "",
        homecafe = "",
        homeblksorting = "",
        homeLightContent = "",
        homeDarkContent = "",
        homesubscriptionblk = "";
      var homejournyimgBlk = [];
      var homeGallery = [];
      if (Object.keys(PropsData.staticblack).length > 0) {
        PropsData.staticblack.map((data) => {
          if (data.staticblocks_slug === "home-about-us") {
            homeAboutBlk = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "home-pantry") {
            homePantryBlk = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "home-promotion") {
            homepromoBlk = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "home-blog-post") {
            homeBlogBlk = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "home-our-journey") {
            homejournyBlk = data.staticblocks_description;
            homejournyimgBlk = data.gallery_images;
            homejournyimgpath = data.gallery_image_path;
            return "";
          }
          if (data.staticblocks_slug === "home-journey-grammed") {
            homegrammedBlk = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "home-order-now") {
            homecafe = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "home-block-sorting") {
            homeblksorting = data.staticblocks_description;
            homeblksorting = homeblksorting.replace("<p>", "");
            homeblksorting = homeblksorting.replace("</p>", "");
            return "";
          }
          if (data.staticblocks_slug === "home-subscription") {
            homesubscriptionblk = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "home-gallery") {
            homeGallery = data;
            return "";
          }
          if (data.staticblocks_slug === "home-dark-content") {
            homeDarkContent = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "home-light-content") {
            homeLightContent = data.staticblocks_description;
            return "";
          }
        });
      }
      homeAboutBlk =
        homeAboutBlk !== "" && homeAboutBlk !== null
          ? Parser(homeAboutBlk)
          : homeAboutBlk;
      homePantryBlk =
        homePantryBlk !== "" && homePantryBlk !== null
          ? Parser(homePantryBlk)
          : homePantryBlk;
      homepromoBlk =
        homepromoBlk !== "" && homepromoBlk !== null
          ? Parser(homepromoBlk)
          : homepromoBlk;
      homeBlogBlk =
        homeBlogBlk !== "" && homeBlogBlk !== null
          ? Parser(homeBlogBlk)
          : homeBlogBlk;
      homejournyBlk =
        homejournyBlk !== "" && homejournyBlk !== null
          ? Parser(homejournyBlk)
          : homejournyBlk;
      homegrammedBlk =
        homegrammedBlk !== "" && homegrammedBlk !== null
          ? Parser(homegrammedBlk)
          : homegrammedBlk;
      homecafe =
        homecafe !== "" && homecafe !== null ? Parser(homecafe) : homecafe;
      homesubscriptionblk =
        homesubscriptionblk !== "" && homesubscriptionblk !== null
          ? Parser(homesubscriptionblk)
          : homesubscriptionblk;
      homeDarkContent =
        homeDarkContent !== "" && homeDarkContent !== null
          ? Parser(homeDarkContent)
          : homeDarkContent;
      homeLightContent =
        homeLightContent !== "" && homeLightContent !== null
          ? Parser(homeLightContent)
          : homeLightContent;

      this.setState({
        staticblacks: PropsData.staticblack,
        homeAboutBlk: homeAboutBlk,
        homePantryBlk: homePantryBlk,
        homepromoBlk: homepromoBlk,
        homeBlogBlk: homeBlogBlk,
        homejournyBlk: homejournyBlk,
        homejournyimgBlk: homejournyimgBlk,
        homejournyimgpath: homejournyimgpath,
        homegrammedBlk: homegrammedBlk,
        homecafe: homecafe,
        homeblksorting: homeblksorting,
        homesubscriptionblk: homesubscriptionblk,
        homeGallery: homeGallery,
        homeDarkContent: homeDarkContent,
        homeLightContent: homeLightContent,
      });
    }

    if (
      PropsData.normalpopuplist !== this.state.normalpopuplist &&
      this.state.normalpopup_flg === "initial"
    ) {
      var normalpopupMain = PropsData.normalpopuplist,
        normalpopupdata = [],
        normalpopupStatus = "no";
      if (normalpopupMain !== "") {
        if (Object.keys(normalpopupMain).length > 0) {
          var normalpopupIds = cookie.load("normalpopupIds");
          var normalpopupIdArr =
            normalpopupIds != "" && normalpopupIds != undefined
              ? normalpopupIds.split("~~")
              : Array();
          if (
            $.inArray(normalpopupMain[0].normalpopup_id, normalpopupIdArr) ===
            -1
          ) {
            normalpopupdata = normalpopupMain[0];
            normalpopupStatus = "yes";
          }
          this.setState({
            normalpopuplist: normalpopupMain,
            normalpopupdata: normalpopupdata,
            normalpopup_status: normalpopupStatus,
            normalpopup_flg: "trigger",
          });
        }
      }
    }

    if (PropsData.normalpopupstatus !== this.state.normalpopupstatus) {
      this.setState({ normalpopupstatus: PropsData.normalpopupstatus });
    }

    if (PropsData.globalsettings !== this.props.globalsettings) {
      var settingsArrList = PropsData.globalsettings[0];
      if (Object.keys(settingsArrList).length > 0) {
        if (settingsArrList.status === "ok") {
          let checkMaintananceMode =
            settingsArrList.result_set.client_maintenance_mode;
          this.setState({ maintanace_mode: checkMaintananceMode });
          if (checkMaintananceMode === "1") {
            if (
              cookie.load("loginpopupTrigger") !== "Yes" &&
              cookie.load("loginpopupTrigger") !== "fromcheckout"
            ) {
              $.magnificPopup.close();
            }
            cookie.save("site_maintenance_popup_open", "1", { path: "/" });
          }
          cookie.save(
            "fb_pixel_id",
            settingsArrList.client_fb_pixel_id !== null
              ? settingsArrList.result_set.client_fb_pixel_id
              : ""
          );
          cookie.save(
            "ga_id",
            settingsArrList.client_google_ga_id !== null
              ? settingsArrList.result_set.client_google_ga_id
              : ""
          );
          cookie.save(
            "gtm_id",
            settingsArrList.client_google_gtm_id !== null
              ? settingsArrList.result_set.client_google_gtm_id
              : ""
          );
          cookie.save(
            "home_page_add_to_cart_availability",
            settingsArrList.home_page_add_to_cart_availability !== null
              ? settingsArrList.result_set
                  .client_skip_home_page_add_to_cart_availability
              : ""
          );
        }
      }
    }
  }

  chooseAvailability(availability) {
    var defaultAvilTy = cookie.load("defaultAvilablityId");
    if (defaultAvilTy !== availability) {
      var cartTotalItems = cookie.load("cartTotalItems");
      cartTotalItems =
        cartTotalItems != "" && cartTotalItems != undefined
          ? parseInt(cartTotalItems)
          : 0;
      if (defaultAvilTy === cateringId && cartTotalItems > 0) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      } else if (
        cookie.load("orderOutletId") != "" &&
        cookie.load("orderOutletId") != undefined
      ) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      }
    }

    var popupIdtxt = "";
    if (availability === deliveryId) {
      popupIdtxt = "#delevery-popup";
    } else if (availability === pickupId) {
      popupIdtxt = "#takeaway-popup";
    } else if (availability === cateringId) {
      cookie.save("defaultAvilablityId", cateringId, { path: "/" });
      $.magnificPopup.close();
      this.props.history.push("/catering");
      return false;
    } else if (availability === reservationId) {
      cookie.save("defaultAvilablityId", reservationId, { path: "/" });
      $.magnificPopup.close();
      this.props.history.push("/reservation");
      return false;
      /* popupIdtxt = '#comingsoon-popup';*/
    }

    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }

  componentDidMount() {
    if (
      cookie.load("triggerAvlPop") != "" &&
      cookie.load("triggerAvlPop") != undefined
    ) {
      var availabilityId = cookie.load("triggerAvlPop");
      cookie.remove("triggerAvlPop", { path: "/" });
      this.chooseAvailability(availabilityId);
    }

    if (cookie.load("orderPopuptrigger") === "Yes") {
      cookie.remove("orderPopuptrigger", { path: "/" });
      setTimeout(function () {
        $.magnificPopup.open({
          items: {
            src: "#order-popup",
          },
          type: "inline",
        });
      }, 300);
    }

    if (cookie.load("loginpopupTrigger") === "Yes") {
      cookie.save("loginpopupTrigger", "fromcheckout", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    }

    if (cookie.load("rfcodeAction") === "Yes") {
      cookie.remove("rfcodeAction");
      $.magnificPopup.open({
        items: {
          src: "#signup-popup",
        },
        type: "inline",
      });
    }

    var RctThis = this;
    $("body")
      .off("click", ".act_order_popup")
      .on("click", ".act_order_popup", function (e) {
        e.preventDefault();

        var odrType = $(this).attr("data-acturl");
        var availabilityId = "";
        if (odrType === "delivery") {
          availabilityId = deliveryId;
        } else if (odrType === "pickup") {
          availabilityId = pickupId;
        } else if (odrType === "catering") {
          availabilityId = cateringId;
        } else if (odrType === "reservation") {
          availabilityId = reservationId;
        }

        if (availabilityId !== "") {
          RctThis.chooseAvailability(availabilityId);
        } else if (availabilityId === "" && odrType === "ordernow") {
          $.magnificPopup.open({
            items: {
              src: "#ordernowpopup",
            },
            type: "inline",
          });
        }
      });

    $(document).ready(function () {
      $(document).on("click", ".home-about-us-dots", function () {
        $(".home-about-us-dots").hide();
        $(".home-about-us-more").show();
      });

      $(document).on("click", ".home-about-us-less", function () {
        $(".home-about-us-more").hide();
        $(".home-about-us-dots").show();
      });

      $(document).on("click", ".home-our-journey-dots", function () {
        $(".home-our-journey-dots").hide();
        $(".home-our-journey-more").show();
      });

      $(document).on("click", ".home-our-journey-less", function () {
        $(".home-our-journey-more").hide();
        $(".home-our-journey-dots").show();
      });
      setTimeout(function () {
        $(".banner.loader-sub-div").remove();
      }, 500);
    });
  }

  componentDidUpdate() {
    // $(document).ready(function() {
    //   $('.home-about-us-more').hide();
    //   $('.home-our-journey-more').hide();
    // })
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
    if (field === "view_pro_data" && value !== "") {
      this.setState(
        { viewProductFlg: "yes", viewProductSlug: value },
        function () {
          this.openProDetailPopup();
        }.bind(this)
      );
    }
    if (field === "view_pro_upate" && value !== "") {
      this.setState({ viewProductFlg: value });
    }
    if (field === "firstcategory" && value !== "") {
      this.setState({ firstcategory: value });
    }
  };

  openProDetailPopup() {
    showLoader("comboPro-" + this.state.viewProductSlug, "Idtext");
    $("#ProductDetailMdl").modal({ backdrop: "static", keyboard: false });
  }

  triggerNormalPopup(trgType) {
    var otherPageActTrigger = "no";
    if (
      (cookie.load("triggerAvlPop") != "" &&
        cookie.load("triggerAvlPop") != undefined) ||
      cookie.load("orderPopuptrigger") === "Yes" ||
      cookie.load("loginpopupTrigger") === "Yes"
    ) {
      otherPageActTrigger = "yes";
    }

    if (
      trgType === "loading" &&
      otherPageActTrigger === "no" &&
      this.state.normalpopup_status === "yes" &&
      Object.keys(this.state.normalpopupdata).length > 0 &&
      cookie.load("promoPopupTrigger") !== "Yes"
    ) {
      var normalpopupIds = cookie.load("normalpopupIds");
      var normalpopupIdsNew =
        normalpopupIds != "" && normalpopupIds != undefined
          ? normalpopupIds + "~~" + this.state.normalpopupdata.normalpopup_id
          : this.state.normalpopupdata.normalpopup_id;
      var normalpopupIdArr = [];
      normalpopupIdArr["normalpopupids"] = normalpopupIdsNew;
      cookie.save("normalpopupIds", normalpopupIdsNew, { path: "/" });
      var $_this_rec = this;
      $.magnificPopup.open({
        items: {
          src: "#normal-popup",
        },
        type: "inline",
        midClick: true,
        closeOnBgClick: false,
        callbacks: {
          close: function () {
            $_this_rec.normalPopupUpdate();
          },
        },
      });
    }

    if (
      (cookie.load("promoPopupTrigger") === "Yes" ||
        (otherPageActTrigger === "no" &&
          this.state.normalpopup_status === "no" &&
          Object.keys(this.state.normalpopupdata).length === 0)) &&
      cookie.load("mailpopopuptrg") !== "yes"
    ) {
      cookie.save("mailpopopuptrg", "yes", { path: "/" });
      cookie.remove("promoPopupTrigger", { path: "/" });
      var globalsettings = this.props.globalsettings;
      if (Object.keys(globalsettings).length > 0) {
        if (globalsettings[0].result_set.client_promocode_options === "1") {
          $.magnificPopup.open({
            items: {
              src: "#promo-check-popup",
            },
            type: "inline",
            midClick: true,
            closeOnBgClick: false,
          });
        }
      }
    }
  }

  normalPopupUpdate() {
    if (cookie.load("mailpopopuptrg") !== "yes") {
      this.props.history.push("/refpage/promopopup");
    }
  }

  handleEmailChange(event) {
    this.setState({ promo_mail_id: event.target.value, promomail_error: "" });
  }

  sendPromoMailFun() {
    var promoEmail = this.state.promo_mail_id;

    var mailErrorTxt = "";
    if (promoEmail === "") {
      mailErrorTxt = "Email Address is required.";
    } else if (!validateEmailFun(promoEmail)) {
      mailErrorTxt = "Invalide Email Address";
    }

    if (mailErrorTxt !== "") {
      this.setState({ promomail_error: mailErrorTxt });
      return false;
    } else {
      showLoader("promomailpopup-cls", "class");

      var qs = require("qs");
      var postObject = {
        app_id: appId,
        email_address: promoEmail,
      };

      axios
        .post(apiUrl + "promotion/user_promotion", qs.stringify(postObject))
        .then((response) => {
          hideLoader("promomailpopup-cls", "class");
          $.magnificPopup.close();
          if (response.data.status === "ok") {
            showCustomAlert(
              "success",
              "You are now a Member of " +
                companyname +
                ". Please check your email for more information."
            );
          } else {
            var errMsgtxt =
              response.data.message !== ""
                ? response.data.message
                : "Sorry! You didn`t have promo code.";
            showCustomAlert("error", errMsgtxt);
          }
          return false;
        });
    }

    return false;
  }

  setdisplayType(displayType) {
    this.setState({ displayType: displayType });
  }
  chooseAvailabilityFun(availability, event) {
    event.preventDefault();
    var defaultAvilTy = cookie.load("defaultAvilablityId");

    if (defaultAvilTy !== availability) {
      var cartTotalItems = cookie.load("cartTotalItems");
      cartTotalItems =
        cartTotalItems != "" && cartTotalItems != undefined
          ? parseInt(cartTotalItems)
          : 0;
      if (defaultAvilTy === cateringId && cartTotalItems > 0) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      } else if (
        cookie.load("orderOutletId") != "" &&
        cookie.load("orderOutletId") != undefined
      ) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      }
    } else if (
      defaultAvilTy === availability &&
      cookie.load("orderOutletId") != "" &&
      cookie.load("orderOutletId") != undefined
    ) {
      /*$.magnificPopup.close();
			this.props.history.push('/products');
			return false;*/
    }

    var popupIdtxt = "";
    if (availability === deliveryId) {
      $(".delivery_outletpoup").find(".outlet_error").html("");
      popupIdtxt = "#delevery-popup";
    } else if (availability === pickupId) {
      popupIdtxt = "#takeaway-popup";
    } else if (availability === cateringId) {
      cookie.save("defaultAvilablityId", cateringId, { path: "/" });
      $.magnificPopup.close();
      this.props.history.push("/");
      return false;
    } else if (availability === reservationId) {
      cookie.save("defaultAvilablityId", reservationId, { path: "/" });
      $.magnificPopup.close();
      this.props.history.push("/reservation");
      return false;
      /*popupIdtxt = '#comingsoon-popup';*/
    }

    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }
  checkActiveDivHd(avlType) {
    var clsTxt = "";
    var availability = cookie.load("defaultAvilablityId");
    if (availability == avlType) {
      clsTxt += "active";
    }
    return clsTxt;
  }
  openOrders(type, event) {
    event.preventDefault();
    $.magnificPopup.open({
      items: {
        src: "#delevery-postcode-popup",
      },
      type: "inline",
    });
  }

  loadHomeSection() {
    if (this.state.homeblksorting !== "") {
      var home_blk_sorting = this.state.homeblksorting.split("|");
      return home_blk_sorting.map((item, index) => {
        if (item.trim() === "home-pantry") {
          if (this.state.homePantryBlk !== "") {
            return (
              <PantryProducts
                {...this.props}
                sateValChange={this.sateValChange}
                homePantryBlk={this.state.homePantryBlk}
              />
            );
          }
        } else if (item.trim() === "home-promotion") {
          if (this.state.homepromoBlk !== "") {
            return (
              <section className="home-promotion" key={index}>
                <PromoProducts
                  {...this.props}
                  sateValChange={this.sateValChange}
                  homepromoBlk={this.state.homepromoBlk}
                />
              </section>
            );
          }
        } else if (item.trim() === "testimonial") {
          return (
            <div key={index}>
              <Testimonial />
            </div>
          );
        } else if (item.trim() === "home-order-now") {
          return <div key={index}>{this.state.homecafe}</div>;
        } else if (item.trim() === "home-our-journey") {
          if (this.state.homejournyBlk !== "") {
            return (
              <section className="home-our-journey" key={index}>
                <div className="container">
                  <div className="hoj-inner">
                    {this.state.homejournyBlk}
                    {this.state.homejournyimgBlk.length > 0 && (
                      <div className="hoj-rhs">
                        <OwlCarousel options={settingsJourney}>
                          {this.state.homejournyimgBlk.map((item, index) => {
                            if (item !== "") {
                              return (
                                <div key={index}>
                                  <img
                                    src={this.state.homejournyimgpath + item}
                                    alt={companyname}
                                  />
                                </div>
                              );
                            }
                          })}
                        </OwlCarousel>
                      </div>
                    )}
                  </div>
                </div>
              </section>
            );
          }
        } else if (item.trim() === "home-journey-grammed") {
          if (this.state.homegrammedBlk !== "") {
            return (
              <section className="home-our-journey-grammed" key={index}>
                <div className="container">
                  <div className="hoj-grammed">
                    {this.state.homegrammedBlk}
                    <div className="grammed-rhs">
                      <div className="grammed-rhs-left">
                        <a href="#">
                          {" "}
                          <img src={grambig} alt={companyname} />{" "}
                        </a>
                      </div>
                      <div className="grammed-rhs-right">
                        <ul>
                          <li>
                            <a href="#">
                              {" "}
                              <img src={gram1} alt={companyname} />{" "}
                            </a>{" "}
                          </li>
                          <li>
                            <a href="#">
                              {" "}
                              <img src={gram2} alt={companyname} />{" "}
                            </a>{" "}
                          </li>
                          <li>
                            <a href="#">
                              {" "}
                              <img src={gram3} alt={companyname} />{" "}
                            </a>{" "}
                          </li>
                          <li>
                            <a href="#">
                              {" "}
                              <img src={gram4} alt={companyname} />{" "}
                            </a>{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            );
          }
        } else if (item.trim() === "home-insta-feed") {
          return (
            <section className="home-insta-feed">
              <InstaFeed {...this.props} />
            </section>
          );
        }
        /* else if (item.trim() === "home-subscription") {
          return (
            <section className="home-our-newletter" key={index}>
              <NewsLetter
                fields={this.state.newsletterfields}
                onChange={this.fieldChange}
                onValid={this.submitNewsletter}
                onInvalid={() => console.log("Form invalid!")}
                homesubscriptionblk={this.state.homesubscriptionblk}
              />
            </section>
          );
        } */
      });
    }
  }

  fieldChange = (field, value) => {
    this.setState(
      update(this.state, { newsletterfields: { [field]: { $set: value } } })
    );
  };

  submitNewsletter = () => {
    const formPayload = this.state.newsletterfields;
    var qs = require("qs");
    var postObject = {
      app_id: appId,
      firstname: formPayload.firstname,
      lastname: formPayload.lastname,
      email: formPayload.email,
      terms: formPayload.terms,
    };
    showLoader("subscribe_submit", "class");
    axios
      .post(apiUrl + "newsletter/subscription", qs.stringify(postObject))
      .then((response) => {
        hideLoader("subscribe_submit", "class");
        $.magnificPopup.close();
        if (response.data.status === "ok") {
          showCustomAlert("success", response.data.message);
          if (
            response.data.message !==
            "Your Email was already subscribed to the Newsletter."
          ) {
            setTimeout(function () {
              window.location.reload();
            }, 2000);
          }
        } else {
          var errMsgtxt =
            response.data.message !== ""
              ? response.data.message
              : "Something went wrong! Try after sometime.";
          showCustomAlert("error", errMsgtxt);
        }
        return false;
      });
  };

  availabilityList() {
    var settingsArr = this.props.globalsettings;
    if (Object.keys(settingsArr).length > 0) {
      var settingsRes = settingsArr[0].result_set.availability;
      var client_theme = settingsArr[0].result_set.client_theme;
      if (settingsRes.length > 0) {
        return settingsRes.map((item, index) => {
          var avilclass = "";
          if (item.availability_id === deliveryId) {
            avilclass = "hci-one";
          } else if (item.availability_id === pickupId) {
            avilclass = "hci-two";
          } else if (item.availability_id === dineinId) {
            avilclass = "hci-three";
          } else if (item.availability_id === reservationId) {
            avilclass = "hci-four";
          } else if (item.availability_id === cateringId) {
            avilclass = "hci-five";
          }
          return (
            <li key={index} className={avilclass}>
              <a
                href="/"
                onClick={this.setAvailabilityFun.bind(
                  this,
                  item.availability_id
                )}
                className={this.checkActiveDivHd(item.availability_id)}
              >
                {(() => {
                  if (item.availability_id === deliveryId) {
                    return (
                      <div>
                        {(() => {
                          if (client_theme == 0) {
                            return (
                              <>
                                {" "}
                                <img className="hci-normal" src={db} />{" "}
                                <img className="hci-hover" src={dbwhite} />{" "}
                              </>
                            );
                          } else if (client_theme == 1) {
                            return (
                              <>
                                {" "}
                                <img
                                  className="hci-normal"
                                  src={dbwhite}
                                />{" "}
                                <img className="hci-hover" src={dbwhite} />{" "}
                              </>
                            );
                          } else if (client_theme == 2) {
                            return (
                              <>
                                {" "}
                                <img className="hci-normal" src={db} />{" "}
                                <img className="hci-hover" src={dbwhite} />{" "}
                              </>
                            );
                          } else {
                            return (
                              <>
                                {" "}
                                <img className="hci-normal" src={db} />{" "}
                                <img className="hci-hover" src={dbwhite} />{" "}
                              </>
                            );
                          }
                        })()}
                      </div>
                    );
                  } else if (item.availability_id === pickupId) {
                    return (
                      <div>
                        {(() => {
                          if (client_theme == 0) {
                            return (
                              <>
                                {" "}
                                <img className="hci-normal" src={ta} />{" "}
                                <img className="hci-hover" src={tawhite} />{" "}
                              </>
                            );
                          } else if (client_theme == 1) {
                            return (
                              <>
                                {" "}
                                <img
                                  className="hci-normal"
                                  src={tawhite}
                                />{" "}
                                <img className="hci-hover" src={tawhite} />{" "}
                              </>
                            );
                          } else if (client_theme == 2) {
                            return (
                              <>
                                {" "}
                                <img className="hci-normal" src={ta} />{" "}
                                <img className="hci-hover" src={tawhite} />{" "}
                              </>
                            );
                          } else {
                            return (
                              <>
                                {" "}
                                <img className="hci-normal" src={ta} />{" "}
                                <img className="hci-hover" src={tawhite} />{" "}
                              </>
                            );
                          }
                        })()}
                      </div>
                    );
                  } else if (item.availability_id === dineinId) {
                    return (
                      <div>
                        {(() => {
                          if (client_theme == 0) {
                            return (
                              <>
                                {" "}
                                <img className="hci-normal" src={din} />{" "}
                                <img className="hci-hover" src={dinwhite} />{" "}
                              </>
                            );
                          } else if (client_theme == 1) {
                            return (
                              <>
                                {" "}
                                <img
                                  className="hci-normal"
                                  src={dinwhite}
                                />{" "}
                                <img className="hci-hover" src={dinwhite} />{" "}
                              </>
                            );
                          } else if (client_theme == 2) {
                            return (
                              <>
                                {" "}
                                <img className="hci-normal" src={din} />{" "}
                                <img className="hci-hover" src={dinwhite} />{" "}
                              </>
                            );
                          } else {
                            return (
                              <>
                                {" "}
                                <img className="hci-normal" src={din} />{" "}
                                <img className="hci-hover" src={dinwhite} />{" "}
                              </>
                            );
                          }
                        })()}
                      </div>
                    );
                  } else if (item.availability_id === reservationId) {
                    return (
                      <div>
                        {(() => {
                          if (client_theme == 0) {
                            return (
                              <>
                                {" "}
                                <img className="hci-normal" src={res} />{" "}
                                <img className="hci-hover" src={reswhite} />{" "}
                              </>
                            );
                          } else if (client_theme == 1) {
                            return (
                              <>
                                {" "}
                                <img
                                  className="hci-normal"
                                  src={reswhite}
                                />{" "}
                                <img className="hci-hover" src={reswhite} />{" "}
                              </>
                            );
                          } else if (client_theme == 2) {
                            return (
                              <>
                                {" "}
                                <img className="hci-normal" src={res} />{" "}
                                <img className="hci-hover" src={reswhite} />{" "}
                              </>
                            );
                          } else {
                            return (
                              <>
                                {" "}
                                <img className="hci-normal" src={res} />{" "}
                                <img className="hci-hover" src={reswhite} />{" "}
                              </>
                            );
                          }
                        })()}
                      </div>
                    );
                  } else if (item.availability_id === cateringId) {
                    return (
                      <div>
                        {(() => {
                          if (client_theme == 0) {
                            return (
                              <>
                                {" "}
                                <img className="hci-normal" src={cater} />{" "}
                                <img className="hci-hover" src={caterwhite} />{" "}
                              </>
                            );
                          } else if (client_theme == 1) {
                            return (
                              <>
                                {" "}
                                <img
                                  className="hci-normal"
                                  src={caterwhite}
                                />{" "}
                                <img className="hci-hover" src={caterwhite} />{" "}
                              </>
                            );
                          } else if (client_theme == 2) {
                            return (
                              <>
                                {" "}
                                <img className="hci-normal" src={cater} />{" "}
                                <img className="hci-hover" src={caterwhite} />{" "}
                              </>
                            );
                          } else {
                            return (
                              <>
                                {" "}
                                <img className="hci-normal" src={cater} />{" "}
                                <img className="hci-hover" src={caterwhite} />{" "}
                              </>
                            );
                          }
                        })()}
                      </div>
                    );
                  }
                })()}

                {item.availability_name}
              </a>
            </li>
          );
        });
      }
    }
  }

  setAvailabilityFun(availability, event) {
    event.preventDefault();
    if (availability === cateringId) {
      return false;
    }
    this.setState(
      { setAvilablityId: availability, error_order_type: "" },
      () => {
        this.chooseAvailabilityFun(availability, event);
      }
    );
  }

  chooseAvailabilityFun(availability, event) {
    event.preventDefault();
    var defaultAvilTy = cookie.load("defaultAvilablityId");
    if (availability !== reservationId && availability !== cateringId) {
      // if (availability === defaultAvilTy) {
      //   this.props.history.push("/menu");
      //   return false;
      // }
    }
    if (defaultAvilTy !== availability) {
      var cartTotalItems = cookie.load("cartTotalItems");
      cartTotalItems =
        cartTotalItems != "" && cartTotalItems != undefined
          ? parseInt(cartTotalItems)
          : 0;
      if (
        cookie.load("orderOutletId") != "" &&
        cookie.load("orderOutletId") != undefined
      ) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      }
    }
    var popupIdtxt = "";
    if (availability === deliveryId) {
      $(".delivery_outletpoup").find(".outlet_error").html("");
      popupIdtxt = "#delevery-postcode-popup";
    } else if (availability === pickupId) {
      /*  if (
        this.state.pickupOutlets !== "" &&
        typeof this.state.pickupOutlets !== undefined &&
        typeof this.state.pickupOutlets !== undefined
      ) {
        if (this.state.pickupOutlets.length === 1) {
          this.pickOutlet(this.state.pickupOutlets[0]);
        } else {
          popupIdtxt = "#takeaway-popup";
        }
      } else { */
      popupIdtxt = "#takeaway-popup";
      /*  } */
    } else if (availability === cateringId) {
      cookie.save("defaultAvilablityId", cateringId, { path: "/" });
      $.magnificPopup.close();
      this.props.history.push("/package");
      return false;
    } else if (availability === reservationId) {
      cookie.save("defaultAvilablityId", reservationId, { path: "/" });
      $.magnificPopup.close();
      this.props.history.push("/reservation");
      return false;
    } else if (availability === dineinId) {
      cookie.save("defaultAvilablityId", dineinId, { path: "/" });
      $.magnificPopup.close();
      popupIdtxt = "#dinein-popup";
    }

    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }

  serviceMaintanance() {
    if (this.state.client_password_maintain !== "") {
      this.setState({ client_password_maintain_error: "" });

      var postObject = {
        app_id: appId,
        client_password: this.state.client_password_maintain,
      };

      var qs = require("qs");

      axios
        .post(
          apiUrl + "settings/checking_maintenance",
          qs.stringify(postObject)
        )
        .then((response) => {
          if (response.data.status === "ok") {
            $(".maintance-popup").modal("hide");
            $(".maintance-popup").addClass("mfp-hide");
            this.setState({ maintanace_popup_hide: true });
            cookie.save("site_maintenance_popup_open", "0", { path: "/" });
            cookie.save("site_maintenance_verify", "1", { path: "/" });
          } else {
            $(".maintance-popup").modal("show");
            this.setState({ client_password_maintain_error: "2" });
            cookie.save("site_maintenance_popup_open", "1", { path: "/" });
            cookie.save("site_maintenance_verify", "0", { path: "/" });
          }
        });
    } else {
      cookie.save("site_maintenance_popup_open", "1", { path: "/" });
      cookie.save("site_maintenance_verify", "0", { path: "/" });
      this.setState({ client_password_maintain_error: "1" });
    }
  }

  handleChangePassword(event) {
    if (event.target.name === "client_password_maintain") {
      this.setState({ client_password_maintain: event.target.value });
    }
  }

  render() {
    let settingsArr = this.props.globalsettings;
    var showPromoPopup = "",
      showNormalPopup = "",
      client_theme = 0;
    if (Object.keys(settingsArr).length > 0) {
      if (Object.keys(settingsArr[0].result_set).length > 0) {
        var client_theme = settingsArr[0].result_set.client_theme;
        if (settingsArr[0].result_set.client_promocode_enable === "1") {
          showNormalPopup =
            settingsArr[0].result_set.client_promo_code_normal_popup_enable ===
            "1"
              ? "yes"
              : "";
          showPromoPopup =
            settingsArr[0].result_set.client_promocode_options === "1"
              ? "yes"
              : "";
        }
      }
    }

    var site_maintenance_verify =
      cookie.load("site_maintenance_verify") !== "" &&
      cookie.load("site_maintenance_verify") !== "undefined" &&
      cookie.load("site_maintenance_verify") !== undefined
        ? cookie.load("site_maintenance_verify")
        : "";

    return (
      <div className="home-main-div">
        {/* Header section */}
        <Header
          homePageState={this.state}
          cartTriggerFlg={this.state.cartTriggerFlg}
          sateValChange={this.sateValChange}
        />

        {/* Home banner section */}
        <section className="white-home">
          <HomeBanner />
        </section>
        <section className="home-content">
          <div className="container">
            <div className="home-content-inner">
              <ul>
                {this.availabilityList()}
                {(() => {
                  if (client_theme == 0) {
                    return this.state.homeLightContent;
                  } else if (client_theme == 1) {
                    return this.state.homeDarkContent;
                  } else if (client_theme == 2) {
                    return this.state.homeLightContent;
                  } else if (client_theme == 3) {
                    return this.state.homeLightContent;
                  } else if (client_theme == 4) {
                    return this.state.homeLightContent;
                  }
                })()}
              </ul>
            </div>
          </div>
        </section>
        {this.loadHomeSection()}
        {Object.keys(this.state.homeGallery).length > 0 && (
          <section className="home-insta-feed dymic-insta-feed">
            <section className="instapro-section">
              <div className="instapro-section-inner">
                <div className="container">
                  <div className="home-insta-inner">
                    <div className="home-insta-lhs">
                      <OwlCarousel options={settingsGallery}>
                        {this.state.homeGallery.gallery_images.map(
                          (loaddatagall, gallindex) => {
                            return (
                              <div
                                key={gallindex}
                                className="home-instafeed-image"
                              >
                                <img
                                  src={
                                    this.state.homeGallery.gallery_image_path +
                                    loaddatagall
                                  }
                                  alt="Gallery"
                                />
                              </div>
                            );
                          }
                        )}
                      </OwlCarousel>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
        )}
        {/* Footer section */}
        <Footer />
        {/*Normal popup Start*/}
        <div
          id="normal-popup"
          className="white-popup mfp-hide popup_sec normal-popup"
        >
          <div className="normal_popup_alert">
            {this.state.normalpopup_status === "yes" &&
              Object.keys(this.state.normalpopupdata).length > 0 && (
                <div>
                  <div className="full-login-new-header">
                    <h3>
                      {" "}
                      {stripslashes(
                        this.state.normalpopupdata.normalpopup_title
                      )}
                    </h3>
                  </div>
                  <div className="normal_popup_cont">
                    {Parser(this.state.normalpopupdata.normalpopup_description)}
                  </div>
                </div>
              )}
          </div>
        </div>
        {/*Normal popup - End*/}

        {/*  Serive Maintanance Popup - start */}
        {this.state.maintanace_mode === "1" &&
          cookie.load("site_maintenance_popup_open") === "1" &&
          (site_maintenance_verify === "" ||
            site_maintenance_verify === "0") && (
            <>
              {this.state.maintanace_popup_hide === false && (
                <div class="mfp-bg mfp-ready"></div>
              )}
              <div className="maintance-popup-main">
                <div
                  className={
                    this.state.maintanace_mode === "1"
                      ? "maintance-popup"
                      : "maintance-popup mfp-hide"
                  }
                  data-target="#myModal"
                  data-toggle="modal"
                  data-backdrop="static"
                  data-keyboard="false"
                >
                  <div className="maintance-popup-container">
                    <div className="mtnce-pup-imager">
                      <img className="mtnce-pup-img" src={maintanceImg} />
                    </div>
                    <div className="maintance-popup-contant">
                      <h3>site is under maintainance</h3>
                      <p>
                        {" "}
                        Please enter admin password to browse through website
                      </p>

                      <div className="maintance-popup-input">
                        <input
                          type="password"
                          placeholder="Enter Admin Password"
                          name="client_password_maintain"
                          value={this.state.client_password_maintain}
                          onChange={this.handleChangePassword.bind(this)}
                        />
                        <a
                          onClick={this.serviceMaintanance.bind(this)}
                          className="button disbl_href_action"
                          title="Continue"
                        >
                          Continue
                        </a>
                      </div>
                      {this.state.client_password_maintain_error === "1" && (
                        <span className="error">This field is required. </span>
                      )}
                      {this.state.client_password_maintain_error === "2" && (
                        <span className="error">Password is incorrect. </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        {/* Serive Maintanance - end */}

        {/*Promo Check Email popup Start*/}
        <div
          id="promo-check-popup"
          className="white-popup mfp-hide popup_sec promo-check-popup"
        >
          <div className="promopopup-maindiv">
            <div className="promopopup-lhs">
              <div className="frm-action-div">
                <div className="frm-top-title">
                  <h3>Join our email list</h3>
                  <p>Enter your Email address to receive your promocode</p>
                </div>

                <div className="frm-inputbtn-div">
                  <div className="form-group">
                    <div className="focus-out">
                      <label>Email Address</label>
                      <input
                        type="email"
                        className="form-control input-focus"
                        value={this.state.promo_mail_id}
                        onChange={this.handleEmailChange.bind(this)}
                      />
                      {this.state.promomail_error !== "" && (
                        <div id="promomail-error">
                          {this.state.promomail_error}
                        </div>
                      )}
                    </div>
                  </div>

                  <button
                    type="button"
                    onClick={this.sendPromoMailFun.bind(this)}
                    className="button promomailpopup-cls"
                  >
                    Subscribe
                  </button>
                </div>

                <div className="frm-bottom-text">
                  <p>
                    SIGN UP FOR EXCLUSIVE UPDATES, NEW PRODUCTS, AND
                    INSIDER-ONLY DISCOUNTS
                  </p>
                </div>
              </div>
            </div>

            <div className="promopopup-rhs">
              <img src={newletterImg} />
            </div>
          </div>
        </div>
        {/*Promo Check Email popup - End*/}

        {this.state.normalpopupstatus !== "initial" &&
          (showNormalPopup === "yes" || showPromoPopup === "yes") &&
          this.triggerNormalPopup("loading")}
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }

  var normalPopupStatus = "initial";
  var normalPopupArr = Array();
  if (Object.keys(state.normalpopuplist).length > 0) {
    if (state.normalpopuplist[0].status === "ok") {
      normalPopupArr = state.normalpopuplist[0].result_set;
      normalPopupStatus = "ok";
    } else {
      normalPopupStatus = "error";
    }
  }

  var tempArr = Array();
  var navigateRst = Array();
  var navigateCmn = Array();
  if (Object.keys(state.product).length > 0) {
    var tempArr = !("menuNavigation" in state.product[0])
      ? Array()
      : state.product[0].menuNavigation;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        navigateRst = tempArr[0].result_set;
        navigateCmn = tempArr[0].common;
      }
    }
  }

  return {
    globalsettings: state.settings,
    categorylist: state.categorylist,
    navigateMenu: navigateRst,
    navigateCommon: navigateCmn,
    staticblack: blacksArr,
    normalpopuplist: normalPopupArr,
    normalpopupstatus: normalPopupStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getCategoryList: () => {
      dispatch({ type: GET_CATEGORY_LIST });
    },
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
    getNormalPopup: () => {
      dispatch({ type: GET_NORMAL_POPUP });
    },
    getMenuNavigation: () => {
      dispatch({ type: GET_MENU_NAVIGATION });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Home);
