/* eslint-disable */
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { showPriceValueNew } from "../Helpers/SettingHelper";
import { connect } from "react-redux";
import cookie from "react-cookies";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import thnkyou_tick_img from "../../common/images/tick.png";
class Thankyou extends Component {
  constructor(props) {
    super(props);
    this.state = { pay_orderid: "", pay_amount: 0 };
  }

  componentDidMount() {
    var userIdTxt =
      typeof cookie.load("UserId") === "undefined" ? "" : cookie.load("UserId");
    var payOrderid =
      typeof cookie.load("payOrderid") === "undefined"
        ? ""
        : cookie.load("payOrderid");
    var payAmount =
      typeof cookie.load("payAmount") === "undefined"
        ? ""
        : cookie.load("payAmount");

    if (userIdTxt !== "" && payOrderid !== "") {
      cookie.remove("payOrderid", { path: "/" });
      cookie.remove("payAmount", { path: "/" });
      this.setState({
        pay_orderid: payOrderid,
        pay_amount: payAmount,
      });
    }

    var pay_orderid = this.state.pay_orderid;
    if (userIdTxt === "" || (payOrderid === "" && pay_orderid === "")) {
      this.props.history.push("/");
    }
  }

  render() {
    return (
      <div className="thankyou-main-div">
        <Header />
        <div className="container">
          <div className="innersection_wrap tnk-you">
            <div className="mainacc_toptext tick">
              <img src={thnkyou_tick_img} alt="Thank You" />
              <h2>Thank You </h2>
              <p>Your catering paymemt has been paid successfully</p>
            </div>
            <div className="thank-order-detaildiv">
              <div className="tnk-detail text-center">
                <h2>YOUR PAYMENT DETAILS</h2>
                <div className="tnk-order">
                  <h3>Order No - {this.state.pay_orderid}</h3>
                  <h4>
                    Catering Paid Amount -{" "}
                    {showPriceValueNew(this.state.pay_amount)}
                  </h4>
                </div>
              </div>
              <div className="tnk-chk-order">
                <Link
                  to={"/myorders/catering"}
                  className="button btn btn_fill btn_md btn_upp btn_green "
                >
                  Go To My Orders
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Footer {...this.props} />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withRouter(Thankyou));
