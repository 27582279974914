/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Accordion, AccordionItem } from "react-light-accordion";
import "react-light-accordion/demo/css/index.css";
import { companyname } from "../Helpers/Config";
import { appId, apiUrl, appName } from "../Helpers/Config";

import { GET_STATIC_BLOCK } from "../../actions";
var Parser = require("html-react-parser");
import axios from "axios";
var qs = require("qs");
import { showAlert } from "../Helpers/SettingHelper";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      footerBlocks: "",
      footerlink: "",
      newsLetterEmail: "",
      isChecked: false,
    };
  }

  componentDidMount() {
    this.props.getStaticBlock();

    var btn = $("#scrollbutton");

    $(window).scroll(function () {
      if ($(window).scrollTop() > 300) {
        btn.addClass("show");
      } else {
        btn.removeClass("show");
      }
    });

    btn.on("click", function (e) {
      e.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, "300");
    });
  }

  componentWillReceiveProps(PropsData) {
    if (PropsData.staticblack !== this.state.footerBlocks) {
      var footerPas = "";
      if (Object.keys(PropsData.staticblack).length > 0) {
        PropsData.staticblack.map((data, index) => {
          if (data.staticblocks_slug === "footer-contents") {
            footerPas = data.staticblocks_description;
            return "";
          }
        });
      }
      footerPas = footerPas !== "" ? Parser(footerPas) : footerPas;
      this.setState({
        footerBlocks: PropsData.staticblack,
        footerlink: footerPas,
      });
    }
  }

  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  };
  checkEmail() {
    if (this.state.newsLetterEmail !== "" && this.state.isChecked == true) {
      var postObject = {
        app_id: appId,
        email: this.state.newsLetterEmail,
      };
      axios
        .post(apiUrl + "newsletter/subscription_new", qs.stringify(postObject))
        .then((res) => {
          if (res.data.status === "ok") {
            var magnfPopup = $.magnificPopup.instance;
            showAlert("Success", "Newsletter Subscribed", magnfPopup);
            setTimeout(function () {
              window.location.reload();
            }, 3000);
          } else {
            showAlert("Error", "Enter a valid email", magnfPopup);
          }
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        });
    }
  }

  handleChangeemail(event) {
    this.setState({ newsLetterEmail: event.target.value });
  }

  render() {
    var todayTimeSp = new Date();
    var yearSp = todayTimeSp.getFullYear();

    return (
      <footer className="footer-main">
        <div className="container">
          <div className="footer-top">
            <div className="footer-newletter">
              {appId == "E338A8BC-4B4A-427A-A683-60A2F1E173D2" ? (
                <>
                  <span>Join our mailing list</span>
                  <h4>
                    Be the first to get the latest deals and specials from JU
                    Signatures!
                  </h4>
                </>
              ) : (
                <>
                  <span>Sign up for our newsletter</span>
                  <h4>
                    {appId == "5AF0459E-1CD7-45A8-8B49-7202E2A401FC"
                      ? "Be the first to know what’s churnin’ next over at Chulop!"
                      : "Be the First to Get the Scoop on Our Freshest Deals, Launches & Recipes!"}
                  </h4>
                </>
              )}
              <div className="f-newletter-form">
                <input
                  type="text"
                  onChange={this.handleChangeemail.bind(this)}
                  value={this.state.newsLetterEmail}
                  placeholder="Email Address"
                />
                <button
                  type="submit"
                  onClick={this.checkEmail.bind(this)}
                  className="button"
                >
                  Subscribe
                </button>
              </div>
              <div className="f-newletter-bottom">
                <input
                  type="checkbox"
                  onChange={this.toggleChange}
                  className="subscribe_accept"
                />
                {appId == "D4A1F6D0-A140-418F-BBBB-56BF5A24B2E2" ? (
                  <label>
                    {" "}
                    By signing up, I agree to receive communications about{" "}
                    {companyname}’s services store events and matters via email
                    and I have also acknowledge & agreed to {companyname}’s
                    Privacy Policy and Terms & Conditions
                  </label>
                ) : (
                  <label>
                    {" "}
                    By signing up, i agree to receive communications about{" "}
                    {companyname}’s services store events and matters via email
                    and i have also acknowledge & agreed to {companyname}’s
                    Privacy Policy and Terms & Conditions
                  </label>
                )}
              </div>
            </div>
            {this.state.footerlink}
            <div className="iview-copy">
              <p>
                Copyright {yearSp} {companyname}.
              </p>
            </div>
            <div className="mobile-footer-nav">
              <Accordion>
                <AccordionItem title="About Us">
                  <ul>
                    <li>
                      <a href="/page/about-us">About Us</a>
                    </li>
                    <li>
                      <a href="/locations">Visit Us</a>
                    </li>
                  </ul>
                </AccordionItem>
                <AccordionItem title="Helpful Links">
                  <ul>
                    <li>
                      <Link to={"/faq"}>FAQ</Link>
                    </li>
                    <li>
                      <Link to={"/terms-and-conditions"}>
                        Terms & Conditions
                      </Link>
                    </li>
                    <li>
                      <Link to={"/privacy-policy"}>Privacy Policy</Link>
                    </li>
                  </ul>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>
        <div className="copyright-section">
          <p>
            Copyright {yearSp} {companyname}.
          </p>
        </div>

        <div className="scrolltop" id="scrollbutton">
          <a href="/" className="disbl_href_action">
            <span>
              <i className="fa fa-angle-double-up ars"></i>
              <i className="fa fa-angle-up ars1"></i>
              <i className="fa fa-angle-up ars2"></i>
            </span>
          </a>
        </div>
      </footer>
    );
  }
}

const mapStateTopProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }
  return {
    staticblack: blacksArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Footer);
